import React from "react"
import { graphql } from "gatsby"
import Imgix, { Picture, Source, buildURL } from "react-imgix"
import widont from "widont"

import ArticleHeader from "../../../components/athena/articleHeader.js"
import Contributors from "../../../components/athena/contributors.js"
import AthenaCta from "../../../components/athena/athenaCta.js"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import Image from "../../../components/image"
import GatedContent from "../../../components/gatedContent"

import styles from "./introduction.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
}

const ResponsiveImage = ({ image, mobileImage, sizes }) => {
  return (
    <Picture>
      <Source
        src={image.url}
        htmlAttributes={{ media: "(min-width: 768px)" }}
        sizes={sizes}
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes",
        }}
      />
      <Imgix
        className={`${styles.image} lazyload`}
        src={mobileImage.url}
        sizes={sizes}
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes",
        }}
        htmlAttributes={{
          src: buildURL(mobileImage.url, { w: 50, blur: 40 }),
          alt: "",
        }}
      />
    </Picture>
  )
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta
  const coverImages = article.coverImages ? article.coverImages[0] : null

  // Content images
  const nested = data.craft.nested
  const number1 = data.craft.number1
  const number2 = data.craft.number2
  const number3 = data.craft.number3
  const number4 = data.craft.number4
  const number5 = data.craft.number5
  const fullBleed1 = data.craft.fullBleed1
  const fullBleed1Mobile = data.craft.fullBleed1Mobile
  const fullBleed2 = data.craft.fullBleed2
  const fullBleed2Mobile = data.craft.fullBleed2Mobile
  const shiftImage1 = data.craft.shiftImage1
  const shiftImage1Mobile = data.craft.shiftImage1Mobile
  const shiftImage2 = data.craft.shiftImage2
  const shiftImage2Mobile = data.craft.shiftImage2Mobile
  const shiftImage4 = data.craft.shiftImage4
  const shiftImage4Mobile = data.craft.shiftImage4Mobile
  const shiftImage5 = data.craft.shiftImage5
  const shiftImage5Mobile = data.craft.shiftImage5Mobile

  return (
    <Layout seo={seo} mode="athena" theme={theme}>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <ArticleHeader
            issue={article.issue}
            title={article.title}
            subtitle={article.articleSubTitle}
            coverImages={coverImages}
          />
          <div className={styles.articleBody}>
            <div className={`${styles.text} ${styles.noBorder}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        We’re living through an unprecedented time in history.
                        COVID-19 descended upon an already softening economy and
                        weak health care system, during one of the most
                        polarized election cycles in our country's history.
                      </p>
                      <p>
                        The world around us was already shifting at warp speed,
                        and the coronavirus only accelerated the engine, giving
                        us little time to make sense of our new reality.
                      </p>
                      <h2>
                        {widont(
                          `In times of uncertainty, it's human nature to retreat`
                        )}
                      </h2>
                      <p>
                        Challenging times can create a sense of paralysis: With
                        a multitude of new, competing theories coming at us,
                        from all angles, we feel overwhelmed. We feel anxious.
                        We’re at home with our families but locked in on
                        computer screens. Sometimes you just need to turn off
                        the iPhone and hunker down in a cardboard box fort with
                        your two-year-old. (For example.)
                      </p>
                      <p>
                        For companies, this can manifest into a tendency to
                        retreat and react. To pause, go on the defensive, and
                        wait for the storm to pass.
                      </p>
                      <p>
                        Individually, fear is overtaking our ability to imagine
                        with optimism.Collectively, this results in a world
                        where we let life happen to us, rather than creating a
                        better reality.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <aside className={styles.nested}>
              <div className={styles.nestedWrapper}>
                <div className={styles.nestedQuote}>
                  <p className="a-h-1">
                    Fear is overtaking our ability to{" "}
                    <span className="italic">imagine with optimism.</span>
                  </p>
                </div>
                <Image
                  height={nested.height}
                  width={nested.width}
                  sizes="(min-width: calc(1600px + 1.5rem)) 1600px, calc(100vw - 1.5rem)"
                  url={nested.url}
                />
              </div>
            </aside>
            <div className={styles.text}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <h2>It’s also human nature to explore</h2>
                      <p>
                        “Humans are driven to explore the unknown, discover new
                        worlds, push the boundaries of our scientific and
                        technical limits, and then push further. The intangible
                        desire to explore and challenge the boundaries of what
                        we know and where we have been has provided benefits to
                        our society for centuries.”
                        <br />
                        &mdash; NASA, “
                        <a
                          className="link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.nasa.gov/exploration/whyweexplore/why_we_explore_main.html#.XoD9xNNKhhE"
                        >
                          Why We Explore
                        </a>
                        ”
                      </p>
                      <p>
                        Exploring the future enables us to make sense of our
                        current state: our place in time, in society, in the
                        universe.
                      </p>
                      <p>
                        Perhaps more importantly, exploring the future now puts
                        us back in the driver's seat. Do we want the future
                        COVID-19, the economy and our politicians grant us? Or
                        do we want to write our own?
                      </p>
                      <h3>
                        {widont(
                          "Exploration reveals new paths to a better society. And to make the most of them, we need to shift how we think about the future."
                        )}
                      </h3>
                      <p>
                        Beyond what we consider a moral and personal obligation
                        to respond to this moment with action, there are good
                        business reasons to do so.{" "}
                        <a
                          className="link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://hbr.org/2010/03/roaring-out-of-recession"
                        >
                          A year-long study from HBR
                        </a>{" "}
                        in the aftermath of the financial crisis found that
                        companies that took a defensive, prevention-focused
                        approach suffered in the long term, while those taking a
                        more progressive approach were 37% more likely to emerge
                        from the downturn as leaders.{" "}
                      </p>
                      <p>
                        While we’re still learning a lot about the pandemic and
                        its effect on society, we’re comforted by our
                        foundational beliefs: that imagination and ingenuity
                        should drive us into the future, not fear.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <aside className={`${styles.pullquote} ${styles.bgGreen}`}>
              <div className="wrapper">
                <div className="columns is-centered">
                  <div className="column is-11-mobile is-10-tablet is-8-desktop">
                    <p className={styles.pullquoteText}>
                      <span className="italic">Exploring the future now</span>{" "}
                      puts us back in the driver's seat.
                    </p>
                  </div>
                </div>
              </div>
            </aside>
            <div className={styles.text}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <h2>Shifting our perspective</h2>
                      <p>
                        Changing your mindset during unprecedented times is no
                        easy task. We’re thinking about five perspectives any
                        company must shift if we want to move from allowing the
                        future to happen to us to approaching the future as a
                        place filled with opportunity.
                      </p>
                      <p>
                        Throughout Athena Issue 02, we'll explore the
                        complexities &mdash; and opportunities &mdash; that
                        present themselves when we attempt to make these shifts.
                        In the name of building a collective human future,
                        please join us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.headline}>
              <h2 className="a-h-1 text-center">The five shifts</h2>
            </div>
            <div className={`${styles.text} ${styles.bgBlue}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className={styles.sectionTitle}>
                      <div
                        className={`${styles.sectionTitleImage} ${styles.sectionTitleImage1} a-h-display`}
                      >
                        <span>
                          <span className="screen-reader-only">Shift #</span>1
                        </span>
                        <Image height={280} width={200} url={number1.url} />
                      </div>
                      <div className={styles.sectionTitleText}>
                        <h3 className="a-h-2">
                          Powerlessness{" "}
                          <span className="screen-reader-only">to</span>
                          <span
                            className={styles.sectionTitleArrow}
                            aria-hidden={true}
                          >
                            →
                          </span>{" "}
                          Control
                        </h3>
                      </div>
                    </div>
                    <div className="user-content-athena">
                      <p>
                        Powerlessness is stoked by fear of what the future
                        holds, taking the mind to the darkest of places and
                        causing us to retreat and react. But do we actually have
                        more control over the future than ever before?Certainly,
                        from a scientific and technological standpoint we do. So
                        what’s holding us back?
                      </p>
                      <h4>How to shift:</h4>
                      <p>
                        Over the past few years, we've conducted a variety of
                        work sessions designed to help clients imagine their
                        future impact in new ways. This practice has revealed a
                        few habits for taking a "control mindset.”
                      </p>
                      <ol>
                        <li>
                          <p>
                            <strong>
                              To begin with, acknowledge that no one person or
                              company has total control.
                            </strong>{" "}
                            Powerlessness is often baked into a feeling of
                            unjustness: That others have it better than us and
                            that the world isn't fair. While this is certainly
                            true, it focuses the mind on what you are lacking,
                            not what resources you have available. We actually
                            start our future planning sessions by looking at
                            drivers of change (economics, technology, the
                            environment, science, infrastructure, demographics).
                            These drivers are often in no one's control, and
                            acknowledging them will help us level the playing
                            field. Our duty is to think ahead about the
                            potential impact these drivers may have, and shape
                            our future accordingly.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Assess what resources you do have.</strong>{" "}
                            Rather than focusing on your personal or
                            organizational debts, focus on your strengths. At
                            Zeus Jones, our greatest strength is our creativity.
                            With an understanding of drivers of change, we focus
                            our energy on how we can use our creativity to solve
                            the challenges that manifest from them.
                          </p>
                          <div className={styles.shiftImage1}>
                            <ResponsiveImage
                              image={shiftImage1}
                              mobileImage={shiftImage1Mobile}
                              sizes="(min-width: 1344px) 850px, (min-width: 768px) 58vw, calc(100vw - 2.5rem)"
                            />
                          </div>
                        </li>
                        <li>
                          <p>
                            <strong>Create solutions.</strong> Start small,
                            learn and pivot. Using the resources in your
                            wheelhouse, what part of the future can you change?
                            For instance, a couple years back we recognized a
                            change occurring in our food system, and used our
                            skills as creative thinkers and technologists{" "}
                            <a
                              className="link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://onomeals.zeusjones.com/"
                            >
                              to create ONO
                            </a>
                            , a speculative food brand that embodied our vision
                            of the future of food. This actually led to new
                            clients approaching us to help them make what at the
                            time was an imaginary idea real. Over the course of
                            a few months, we were able to move from feeling like
                            the future of food was out of our control to
                            actually building it.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <aside
              className={`${styles.pullquote} ${styles.pullquoteLeft} ${styles.bgRed}`}
            >
              <div className="wrapper">
                <div className="columns is-centered">
                  <div className="column is-11-mobile is-offset-1-mobile is-offset-0-tablet is-10-tablet is-8-desktop">
                    <p className={styles.pullquoteText}>
                      <span className="italic">Create solutions.</span> Start
                      small, learn, pivot.
                    </p>
                  </div>
                </div>
              </div>
            </aside>

            <div className={`${styles.text} ${styles.bgLime}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className={styles.sectionTitle}>
                      <div
                        className={`${styles.sectionTitleImage} ${styles.sectionTitleImage2} a-h-display`}
                      >
                        <span>
                          <span className="screen-reader-only">Shift #</span>2
                        </span>
                        <Image height={280} width={218} url={number2.url} />
                      </div>
                      <div
                        className={`${styles.sectionTitleText} ${styles.isRight}`}
                      >
                        <h3 className="a-h-2">
                          Pessimism{" "}
                          <span className="screen-reader-only">to</span>
                          <span
                            className={styles.sectionTitleArrow}
                            aria-hidden={true}
                          >
                            →
                          </span>{" "}
                          Optimism
                        </h3>
                      </div>
                    </div>
                    <div className="user-content-athena">
                      <p>
                        In a time when our tendency has been to look at the
                        future with a dystopian mindset, we think there is power
                        in shifting toward a more optimistic vantage point. If
                        history is any indication, times of economic hardship
                        actually spur the creation of new inventions and
                        innovations.
                      </p>
                      <p>
                        While this particular moment might feel unprecedented,
                        it also presents incredible challenges for businesses
                        and entrepreneurs to grab. So the question becomes, how
                        does one train their brain to look at a crisis with an
                        optimistic mindset?
                      </p>
                      <h4>How to shift:</h4>
                      <ol>
                        <li>
                          <p>
                            <strong>Change your environment.</strong> “From an
                            early age, babies and children pick up the emotional
                            vibes in their homes. If the atmosphere is relaxed
                            and loving, children blossom even if they innately
                            have a tendency towards anxiety,”{" "}
                            <a
                              className="link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.nbcnews.com/better/health/how-train-your-brain-be-more-optimistic-ncna795231"
                            >
                              says Carol Ward
                            </a>
                            , a licensed psychotherapist in an interview with
                            NBC News. “But if the home environment is tense and
                            filled with dysfunction, optimism is one of the
                            first things to go."If your environment &mdash; at
                            work or home &mdash; isn’t conducive to feeling like
                            you can think about the future, consider how you
                            might change it up. Carving out a space of your own
                            can go a long way toward putting you in a mindset.
                          </p>
                        </li>
                        <li>
                          <p>
                            <a
                              className="link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.nytimes.com/2003/02/04/health/behavior-finding-happiness-cajole-your-brain-to-lean-to-the-left.html?mcubz=1"
                            >
                              <strong>Use the left side of your brain.</strong>
                            </a>{" "}
                            Research reveals that when people are emotionally
                            stressed, the most active parts of the brain are the
                            amygdala and the right prefrontal cortex. When in
                            positive moods &mdash; upbeat, enthusiastic and
                            energized &mdash; there is heightened activity in
                            the left prefrontal cortex. By practicing
                            mindfulness, often spurred by activities like
                            meditation and yoga, studies show we can actually
                            train ourselves to use the left side of the brain
                            more &mdash; inducing a more optimistic mindset.
                          </p>
                          <div className={styles.shiftImage2}>
                            <ResponsiveImage
                              image={shiftImage2}
                              mobileImage={shiftImage2Mobile}
                              sizes="(min-width: 1344px) 850px, (min-width: 768px) 58vw, calc(100vw - 2.5rem)"
                            />
                          </div>
                        </li>
                        <li>
                          <p>
                            <a
                              className="link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.nytimes.com/2020/02/18/smarter-living/how-to-be-more-optimistic.html"
                            >
                              <strong>Write down the good.</strong>
                            </a>{" "}
                            A study found that writing down the good can help
                            boost optimism. While this can be applied to your
                            personal life, it's also true in your work: As much
                            of our work focuses on addressing societal issues,
                            we can get bogged down in problem framing.
                            Acknowledging problems is important, to be sure, but
                            moving towards considering how we could make them
                            better is an important step in shifting towards an
                            optimistic view of the future.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.fullImage}>
              <div className="wrapper wrapper--full-bleed">
                <ResponsiveImage
                  image={fullBleed1}
                  mobileImage={fullBleed1Mobile}
                  sizes="(min-width: 1800px) 1800px, 100vw"
                />
              </div>
            </div>

            <div className={`${styles.text} ${styles.bgOrange}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className={styles.sectionTitle}>
                      <div
                        className={`${styles.sectionTitleImage} ${styles.sectionTitleImage3} a-h-display`}
                      >
                        <span>
                          <span className="screen-reader-only">Shift #</span>3
                        </span>
                        <Image height={280} width={198} url={number3.url} />
                      </div>
                      <div className={styles.sectionTitleText}>
                        <h3 className="a-h-2">
                          Prediction{" "}
                          <span className="screen-reader-only">to</span>
                          <span
                            className={styles.sectionTitleArrow}
                            aria-hidden={true}
                          >
                            →
                          </span>{" "}
                          Imagining
                        </h3>
                      </div>
                    </div>
                    <div className="user-content-athena">
                      <p>
                        Businesses have often focused on predicting the future.
                        We think this is a broken proposition, and believe that
                        creativity is our greatest tool in challenging times.
                        How do we move from a prediction to imagination mindset?
                        What tools does imagining the future require?
                      </p>
                      <h4>How to shift:</h4>
                      <ol>
                        <li>
                          <p>
                            <strong>
                              Recognize “predicting” based on trend lines is
                              easy, but rarely accurate.
                            </strong>{" "}
                            When we started working with clients on trajectory
                            planning, we observed a tendency among futurists and
                            trend analysts to predict where the future was
                            heading. In many ways, predicting is a default: It's
                            baked in clear methodologies that imagining lacks.
                            Yet time and again, the most innovative and
                            successful companies of the past century don’t just
                            predict along trend lines; they take creative leaps.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Trends are inspiration, not facts.</strong>{" "}
                            Trends are actually often misused as predictions of
                            where the future is definitively headed. What we've
                            found is that trends should actually be used as
                            inspiration for what might be.
                          </p>
                          <p>
                            When we frame such trends as inspiration for
                            imagining, we allow our brains to head to an
                            entirely different place. In this mindset, the
                            future is ours to create. This also requires
                            unlocking a sense of control and optimism.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Allow many futures to exist.</strong>{" "}
                            Another hurdle the prediction mindset presents is
                            the notion there is one definitive answer. We've
                            found this is a false premise that wastes energy.
                            Instead, allow multiple, variant futures to exist.
                            By freeing ourselves from getting the answer “right”
                            to exploring the many possibilities, we allow the
                            mind to shift towards creating solutions instead of
                            trying to get the best score.
                          </p>
                          <p>
                            Keep in mind why this shift is so important. We
                            believe that by shifting toward an imaginative
                            mindset, we unlock our ability to use creativity.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.fullImage}>
              <div className="wrapper wrapper--full-bleed">
                <ResponsiveImage
                  image={fullBleed2}
                  mobileImage={fullBleed2Mobile}
                  sizes="(min-width: 1800px) 1800px, 100vw"
                />
              </div>
            </div>

            <div className={`${styles.text} ${styles.bgPurple}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className={styles.sectionTitle}>
                      <div
                        className={`${styles.sectionTitleImage} ${styles.sectionTitleImage4} a-h-display`}
                      >
                        <span>
                          <span className="screen-reader-only">Shift #</span>4
                        </span>
                        <Image height={257} width={296} url={number4.url} />
                      </div>
                      <div
                        className={`${styles.sectionTitleText} ${styles.isRight}`}
                      >
                        <h3 className="a-h-2">
                          Time as an anchor{" "}
                          <span className="screen-reader-only">to</span>
                          <span
                            className={styles.sectionTitleArrow}
                            aria-hidden={true}
                          >
                            →
                          </span>{" "}
                          Time is irrelevant
                        </h3>
                      </div>
                    </div>
                    <div className="user-content-athena">
                      <p>
                        Popular prediction frameworks approach time as a
                        defining construct for framing the future. As humans,
                        why do we orient ourselves in timelines? Is defining the
                        future in terms of a timeline even helpful? Or does it
                        place us in a predictive and reactionary mindset,
                        instead of an imaginative one?
                      </p>
                      <h4>How to shift:</h4>
                      <ol>
                        <li>
                          <p>
                            <strong>
                              Depart from frameworks that rigidly predict time.
                            </strong>{" "}
                            For instance, when we embarked on our future
                            planning work a few years back, we anchored
                            ourselves in the{" "}
                            <a
                              className="link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://hbr.org/2019/02/mckinseys-three-horizons-model-defined-innovation-for-years-heres-why-it-no-longer-applies"
                            >
                              Horizon model
                            </a>
                            . We have found this model can handicap your ability
                            to imagine and create the future, as it is built on
                            massive assumptions about cultural events. As we’ve
                            just experienced, no one could have accurately
                            predicted the arrival of this particular coronavirus
                            at this particular time.
                          </p>
                          <p>
                            This is a crucial reason for why preparing for
                            multiple trajectories is essential; we shouldn’t be
                            glued to predicted shifts in society. We believe in
                            preparing for many futures in our trajectory
                            planning, and constantly stay on the lookout for
                            cultural shifts that may accelerate one path or
                            another. We think you should revisit and adjust your
                            long-range planning every year.
                          </p>
                          <div className={styles.shiftImage4}>
                            <ResponsiveImage
                              image={shiftImage4}
                              mobileImage={shiftImage4Mobile}
                              sizes="(min-width: 1344px) 850px, (min-width: 768px) 58vw, calc(100vw - 2.5rem)"
                            />
                          </div>
                        </li>
                        <li>
                          <p>
                            <strong>
                              Acknowledge time as a human-made construct.
                            </strong>{" "}
                            Physicist Carlo Rovelli has been exploring this for
                            decades.Rovelli contends that time is a useless
                            construct in the formation of physical laws, and
                            that instead it emerges as a statistical tool. In
                            super-layperson terms: Time is a framework that
                            allows us humans to make sense of our
                            surroundings.It’s more useful in looking to the past
                            than the future. Instead, imagine the future you
                            want, and work toward it.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Assume ten years out is today.</strong> So
                            if time is irrelevant, when considering the future,
                            what do we replace it with? Our brains still need
                            anchoring devices to design against. We've found it
                            useful to design solutions for an imagined world
                            that exists 10-15 years out, and to adopt a mindset
                            that ten years from now will occur today. This puts
                            us in a mindset of immediacy, spurring a desire to
                            build new solutions now rather than procrastinate.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <aside
              className={`${styles.pullquote} ${styles.pullquoteTopLeft} ${styles.bgBlue}`}
            >
              <div className="wrapper">
                <div className="columns is-centered">
                  <div className="column is-11-mobile is-offset-1-mobile is-offset-0-tablet is-10-tablet is-8-desktop">
                    <p className={styles.pullquoteText}>
                      Imagine the future you want,{" "}
                      <span className="italic">and work towards it.</span>
                    </p>
                  </div>
                </div>
              </div>
            </aside>

            <div className={`${styles.text} ${styles.bgPeachDark}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className={styles.sectionTitle}>
                      <div
                        className={`${styles.sectionTitleImage} ${styles.sectionTitleImage5} a-h-display`}
                      >
                        <span>
                          <span className="screen-reader-only">Shift #</span>5
                        </span>
                        <Image height={280} width={249} url={number5.url} />
                      </div>
                      <div className={styles.sectionTitleText}>
                        <h3 className="a-h-2">
                          Telling <span className="screen-reader-only">to</span>
                          <span
                            className={styles.sectionTitleArrow}
                            aria-hidden={true}
                          >
                            →
                          </span>{" "}
                          Feeling
                        </h3>
                      </div>
                    </div>
                    <div className="user-content-athena">
                      <p>
                        The default for exploring futures is to tell a story.
                        Every Orwellian dystopia is centered on a classic
                        narrative arc, where the storyteller controls how the
                        viewer or reader navigates through their depicted
                        future. Emotions are treated as a result, an
                        after-effect of the story told. What happens when we
                        allow emotions to lead us in an exploration of the
                        future?
                      </p>
                      <h4>How to shift:</h4>
                      <ol>
                        <li>
                          <p>
                            <strong>Imagine a feeling.</strong> Ideas are a
                            dime-a-dozen, and future planning often falls down
                            when it lives solely as obtuse bullets on a Keynote
                            slide. Instead, make people feel. Some of our most
                            successful futures work centers around building
                            “Creative Universes.” That has us imagining many
                            dimensions of the future, today, and building out an
                            entire world. What does this future feel like?
                            What’s the best way of evoking that feeling?{" "}
                            <a
                              className="link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.amazon.com/How-Emotions-Are-Made-Secret/dp/1469292084"
                            >
                              Feelings are not shared universally
                            </a>
                            , so we start with descriptions that call out
                            specific cultural references and metaphors; this
                            first step is about landing on shared associations
                            and aspirations.
                          </p>
                          <div className={styles.shiftImage5}>
                            <ResponsiveImage
                              image={shiftImage5}
                              mobileImage={shiftImage5Mobile}
                              sizes="(min-width: 1344px) 850px, (min-width: 768px) 58vw, calc(100vw - 2.5rem)"
                            />
                          </div>
                        </li>
                        <li>
                          <p>
                            <strong>Build it.</strong> Sometimes, to capture a
                            feeling and the offering that will create it, we’ll
                            build out a physical space &mdash; where you can
                            touch and feel and even smell the future product
                            offerings. Other times, that means creating
                            different creative and experience stories.
                            Occasionally it means making digital product or
                            service experiences and then having some guinea pigs
                            navigate them. The avenues for evoking emotions are
                            endless &mdash; it’s about finding an execution that
                            can capture your specific offering, and also then
                            become a sales tool for rallying your organization.
                            We believe that if you can make people feel it, you
                            can make it feel inevitable.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Show it in culture.</strong> The future
                            shouldn’t exist in a bubble. You have to build the
                            world around it too. What are the conditions of the
                            future you’ve created? We’ve built out site
                            experiences that get the audience in the headspace
                            of the society we’re building. We’ve also assembled
                            groups of think tanks that experience the product or
                            future and react in real time. This is a crucial
                            step: not only is it important to have your
                            prototype of the future built upon by other people,
                            but you need to show the world you’re going to help
                            create.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.text} ${styles.textNoPb}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="mb-10 user-content-athena">
                      <h2>The upshot of all of this:</h2>
                      <p>
                        Prediction means you’re relying on what was true before.
                        And as we’ve seen, what’s “true” can change radically
                        &mdash; and radically fast. Instead, we’re focused on
                        mindsets and tools that help us all imagine and create
                        better futures &mdash; as companies and as people.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Form */}
                <div className="columns is-centered is-tablet">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className={styles.form}>
                      <div className="mb-2">
                        <h2 className={styles.formTitle}>
                          {widont(`Ready to plan the future of your business?`)}
                        </h2>
                      </div>
                      <div className={`${styles.formBody} body`}>
                        <p>
                          We’ve created a trend report and workbook to help.
                          Fill out the form below and we’ll send it right over.
                        </p>
                      </div>
                      <GatedContent
                        uri={article.uri}
                        formId="b62d91cb-f436-42bb-b2e8-c24427e65e29"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            <AthenaCta data={athenaCta} />
            <div className={styles.credits}>
              <div className="wrapper">
                <div className="columns is-tablet">
                  <div className="column is-10-tablet">
                    <p>
                      Photography courtesy the{" "}
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.flickr.com/photos/projectapolloarchive/"
                      >
                        Project Apollo Archive
                      </a>{" "}
                      and the Metropolitan Museum of Art. Most of these photos
                      document the 1969-1972 spaceflight program that
                      successfully landed humans on the moon.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      nested: asset(id: 4820) {
        url
        width
        height
      }
      number1: asset(id: 4821) {
        url
        width
        height
      }
      number2: asset(id: 4822) {
        url
        width
        height
      }
      number3: asset(id: 4823) {
        url
        width
        height
      }
      number4: asset(id: 4824) {
        url
        width
        height
      }
      number5: asset(id: 4825) {
        url
        width
        height
      }
      fullBleed1: asset(id: 4826) {
        url
        width
        height
      }
      fullBleed1Mobile: asset(id: 4912) {
        url
        width
        height
      }
      fullBleed2: asset(id: 4827) {
        url
        width
        height
      }
      fullBleed2Mobile: asset(id: 4913) {
        url
        width
        height
      }
      shiftImage1: asset(id: 4828) {
        url
        width
        height
      }
      shiftImage1Mobile: asset(id: 4914) {
        url
        width
        height
      }
      shiftImage2: asset(id: 4829) {
        url
        width
        height
      }
      shiftImage2Mobile: asset(id: 4916) {
        url
        width
        height
      }
      shiftImage4: asset(id: 4830) {
        url
        width
        height
      }
      shiftImage4Mobile: asset(id: 4918) {
        url
        width
        height
      }
      shiftImage5: asset(id: 4831) {
        url
        width
        height
      }
      shiftImage5Mobile: asset(id: 4919) {
        url
        width
        height
      }
    }
  }
`
